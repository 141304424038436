import { graphql } from 'gatsby'
import React from 'react'

import Post from './post/post'
import Meta from '../components/meta/meta'
import Layout from '../components/layout/layout'
import Page from './page/page'

interface Props {
  data: GatsbyTypes.PostByPathQuery
  location: Location
}

const Template: React.FC<Props> = ({ data, location }: Props) => {
  const isPage = data.post?.frontmatter?.layout != 'page'
  return (
    <div>
      <Layout location={location}>
        <Meta
          title={data.post?.frontmatter?.title || ''}
          site={data.site?.meta}
          description={data.post?.frontmatter?.description || ''}
        />
        {isPage ? (
          <Post
            data={data}
            options={{
              isIndex: false,
            }}
          />
        ) : (
          <Page data={data} location={location} />
        )}
      </Layout>
    </div>
  )
}

export default Template

export const pageQuery = graphql`
  query PostByPath($path: String!) {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
        twitter
      }
    }
    post: markdownRemark(frontmatter: { path: { eq: $path } }) {
      id
      html
      frontmatter {
        layout
        title
        path
        category
        tags
        description
        date(formatString: "YYYY/MM/DD")
        image
      }
    }
  }
`
