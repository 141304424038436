import { Link } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import React from 'react'

import Button from '../../components/button/button'

import './style.scss'

import $ from 'jquery'

const getDescription = (content: string): string => {
  const body = content.replace(
    /<blockquote>/g,
    '<blockquote class="blockquote">'
  )
  if (body.match('<!--more-->')) {
    const [description] = body.split('<!--more-->')
    return description
  }
  return body
}

interface Props {
  data: GatsbyTypes.PostByPathQuery
  options: {
    isIndex: boolean
  }
}

const Post: React.FC<Props> = ({ data, options }: Props) => {
  const frontmatter = data.post?.frontmatter
  const path = frontmatter?.path || ''
  const image = frontmatter?.image || null
  const { isIndex } = options
  const html = data.post?.html || ''
  const isMore = isIndex && !!html.match('<!--more-->')
  const isHero = !!html.match('<!--hero-->')

  // This is kludgy, but allows the standards page to use jquery filtering
  if (typeof document !== 'undefined' && path == '/standards/') {
    $(document).ready(function() {
      $('#search-input').on('keyup', function() {
          var value = $(this).val().toLowerCase();
        $("#standards-table tr").filter(function() {
            $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
        });
      });
    })
  }

  return (
    <div>
      <section className="article" key={path}>
        <div className="container">
        {isHero && image?.childImageSharp?.fluid && (
                <Img
                  fluid={image.childImageSharp.fluid as FluidObject}
                  style={{ display: 'block', margin: '0 auto 1.5rem' }}
                  alt={frontmatter?.title}
                />
          )}
          <h1>{frontmatter?.title}</h1>    
          <div className="info mb-0">  
            {/* <time dateTime={frontmatter?.date}>{frontmatter?.date}</time>
          
            <Badge label={frontmatter?.category || ''} primary={true} />
            {(frontmatter?.tags || []).map((tag, index) => (
              <Badge label={tag as string} primary={false} key={index} />
            ))} */}
          </div>
          
          <div className="row">
            <div
              className="content col-12"
              dangerouslySetInnerHTML={{
                __html: isMore ? getDescription(html) : html,
              }}
            />
          </div>
          {isMore && <Button path={path} label="MORE" primary={true} />}
          {!isIndex}
        </div>
      </section>
    </div>
  )
}

export default Post
