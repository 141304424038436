import React from 'react'
import { Link } from 'gatsby'

interface Props {
  path: string
  label: string
  primary: boolean
}

const Button: React.FC<Props> = ({ path, label, primary }: Props) => {
  return (
    <a 
      className={`readmore btn btn-outline-primary btn-block ${
        primary ? 'btn-outline-primary' : 'btn-outline-secondary'
      }`} 
      href={path}>
        {label}
    </a>
  )
}

export default Button
